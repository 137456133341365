import React, {Component} from 'react'
import SignUpForm from './SignUpForm'


class Home extends Component {
  render() {
    return   (
      <div id="Home" >
          <div className="title-container">
            <h1 id="home-title">SkateSesh(beta)</h1>
          </div>
      </div>
    )
  }
}

export default Home
